import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../Constants";
import Select from "react-select";
import axiosConfig from "../../axiosConfig";
import search_icon from "../../assets/images/search.svg";
import {
  DatePicker,
  Select as ANTDSelect,
  Pagination,
  notification,
  Tooltip,
} from "antd";
import DataFetchIngLoader from "../general/DataFetchIngLoader";
import NoData from "../general/NoData";
import CarDetailsCard from "../includes/DVLA/CarDetailsCard";
import { CSVLink } from "react-csv";
import downloadIcon from "../../assets/images/Export CSV.svg";
import { formatDatev2 } from "../../utils/HelperFunctions";
import UseDebounce from "../general/UseDebounce";

function DVLAPairs() {
  const [isLoading, setLoading] = useState(true);
  const [approvedPairs, setApprovedPairs] = useState([]);
  const [siteCodes, setSiteCodes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [pageSize, setPageSize] = useState(10); // Initial page size
  const [paginationData, setPaginationData] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showValue, setShowValue] = useState("ANPR");
  const [statusValue, setStatusValue] = useState("Submitted to DVLA");

  const [search, setSearch] = useState({
    siteCode: null,
    siteName: null,
    startDate: null,
    endDate: null,
    plate: null,
  });

  const debouncingSearch = UseDebounce(search, 500);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    // Fetch data based on the new pageSize and reset the current page to 1
    setCurrentPage(1);
  };

  const handleSelectChange = (event, id) => {
    const selectedValue = event.target.value;

    // Update the state to add the "reason" property to the selected object
    setApprovedPairs((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, reason: selectedValue } : item
      )
    );
  };
  function formatDatev1(inputDate) {
    const dateObject = new Date(inputDate);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
    const year = dateObject.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    if (inputDate) {
      return `${year}-${formattedMonth}-${formattedDay}`;
    } else {
      return null;
    }
  }
  const fetchApprovedPairs = () => {
    const queryParams = {
      submittedPairInput: JSON.stringify({
        site: search.siteCode?.value ? search.siteCode?.value : search.siteCode,
        StartDate: formatDatev1(startDate),
        EndDate: formatDatev1(endDate),
        PlateNumber: search?.plate?.length > 0 ? search.plate : null,
        source: showValue,
        status: statusValue,
        PageSize: pageSize,
        PageNumber: currentPage,
      }),
    };
    axiosConfig
      .get(`GetSubmittedRecordstoDVLAForUI/`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode, data, paginationData } = res.data;
        if (statusCode === 200) {
          setApprovedPairs(data ? data : []);
          setPaginationData(paginationData);
        } else {
          setApprovedPairs([]);
          setPaginationData({});
          setCurrentPage(1);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setApprovedPairs(null);
      });
  };

  const [csv, setcsv] = useState([]);

  const fetchApprovedPairsCsv = () => {
    const queryParams = {
      submittedPairInput: JSON.stringify({
        site: search.siteCode?.value ? search.siteCode?.value : search.siteCode,
        StartDate: formatDatev1(startDate),
        EndDate: formatDatev1(endDate),
        PlateNumber: search?.plate?.length > 0 ? search.plate : null,
        source: showValue,
        status: statusValue,
        PageSize: 10000,
        PageNumber: currentPage,
      }),
    };
    axiosConfig
      .get(`GetSubmittedRecordstoDVLAForUI/`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode, data, paginationData } = res.data;
        if (statusCode === 200) {
          setcsv(data ? data : []);
          setPaginationData(paginationData);
        } else {
          setcsv([]);
          setPaginationData({});
          setCurrentPage(1);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setcsv(null);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchApprovedPairs();
    // fetchApprovedPairsCsv();
  }, [
    currentPage,
    pageSize,
    debouncingSearch,
    startDate,
    endDate,
    showValue,
    statusValue,
  ]);

  const fetchSitecodes = () => {
    const queryParams = {
      siteInput: JSON.stringify({
        sourceScreen: "DVLA",
      }),
    };

    axiosConfig
      .get(`cleanPairsGetSiteConfigforUI`, {
        params: queryParams,
      })
      .then((res) => {
        const { statusCode, data } = res.data;
        if (statusCode === 200) {
          const formattedOptions = data.map((item) => ({
            value: item.site,
            label: item.site,
          }));
          setSiteCodes(formattedOptions);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchSitecodes();
  }, []);
  function formatAntdDatePickerDate(selectedDate) {
    // Use the `format` function from moment.js to format the date
    return selectedDate.format("YYYY-MM-DD");
  }
  const handleSearch = (column, value) => {
    const data = { ...search };

    if (column === "startDate" || column === "endDate") {
      if (value) {
        const selectedDate = value;
        const formattedDate = formatAntdDatePickerDate(selectedDate);
        data[column] = formattedDate;
      } else {
        data[column] = null;
      }
    } else {
      data[column] = value;
    }

    setSearch(data);
    setCurrentPage(1);
  };

  const handleStartDate = (s, e) => {
    setStartDate(e);
    setEndDate(e);
    setCurrentPage(1);
  };
  const handleendDate = (s, e) => {
    setEndDate(e);
    setCurrentPage(1);
  };

  const headers = [
    { label: "Id", key: "id" },
    { label: "Site Code", key: "site" },
    { label: "Entry plate", key: "entryPlateId" },
    { label: "Entry AI Correction", key: "entryAICorrection" },
    { label: "Entry Plate Before AI", key: "entryPlateBeforeAI" },
    { label: "Entry Motion", key: "entryMotion" },
    { label: "Entry Time", key: "entryTime" },
    { label: "Exit Plate", key: "exitPlate" },
    { label: "Exit AI Correction", key: "exitAICorrection" },
    { label: "Exit Plate Before AI", key: "exitPlateBeforeAI" },
    { label: "Exit Motion", key: "exitMotion" },
    { label: "Exit Time", key: "exitTime" },
    { label: "Violation Type", key: "violationType" },
    { label: "Duration", key: "duration" },
    { label: "Violation Time", key: "violationTime" },
    { label: "Make", key: "make" },
    { label: "Model", key: "model" },
    { label: "Color", key: "color" },
    { label: "Fuzzy Match Percentage", key: "fuzzyMatchPercentage" },

    // fuzzyMatchPercentage

    { label: "Car Web Status", key: "carwebStatus" },
    { label: "Good To Print", key: "goodToPrint" },
    { label: "Category", key: "category" },
    { label: "Entry Image", key: "entryImage2" },
    { label: "Entry Plate Image", key: "entryImage1" },
    { label: "Exit Image", key: "exitImage2" },
    { label: "Exit Plate Image", key: "exitImage1" },
    // { label: "Good to Print", key: "goodToPrint" },
    { label: "Standard", key: "isStandard" },
  ];
  function convertToUpperCaseAndRemoveSpaces(inputString) {
    // Convert the string to uppercase
    let uppercasedString = inputString.toUpperCase();

    // Remove spaces from the string
    let stringWithoutSpaces = uppercasedString.replace(/\s/g, "");

    return stringWithoutSpaces;
  }

  //-------| "Show" Dropdown Data |-------//
  const sourceData = [
    {
      value: "ALL",
      label: "ALL",
    },

    {
      value: "ANPR", //Default Selection
      label: "ANPR",
    },

    {
      value: "CCTV",
      label: "CCTV",
    },

    {
      value: "StarTraq",
      label: "StarTraq",
    },
  ];

  //-------| "Status" Dropdown Data |-------//
  const statusData = [
    {
      value: "Submitted to DVLA", //Default Selection
      label: "Submitted to DVLA",
    },

    {
      value: "DVLA Send",
      label: "DVLA Send",
    },

    {
      value: "DVLA Delayed Send",
      label: "DVLA Delayed Send",
    },

    {
      value: "ReSubmitted to DVLA",
      label: "ReSubmitted to DVLA",
    },
    {
      value: "DVLA Limit",
      label: "DVLA Limit",
    },
  ];

  const handleSourceChange = (selectedOption) => {
    const selectedValue =
      selectedOption && selectedOption.value !== "ALL"
        ? selectedOption.value
        : null;
    setShowValue(selectedValue);
    setCurrentPage(1);
  };

  const handleStatusChange = (selectedOption) => {
    const selectedValue = selectedOption?.value;
    setStatusValue(selectedValue);
    setCurrentPage(1);
  };

  const startNum = paginationData?.startCount ?? "--";
  const endNum = paginationData?.endCount ?? "--";
  const totalRecords = paginationData?.count ?? "--";
  const noRecords =
    totalRecords === "--" && startNum === "--" && endNum === "--";

  return (
    <Container>
      <TopSection>
        {/* <Title>Submitted to DVLA</Title> */}
        <Title>Approved Violations</Title>
        <RightSection>
          {/* <Download
						data={approvedPairs}
						headers={headers}
						filename={"DVLA Submitted data .csv"}
					>
						Download CSV
					</Download> */}
          <div
            style={{
              opacity: 0.5,
              pointerEvents: "none",
            }}
          >
            <CsvIcon
              data={startDate?.length === 0 ? approvedPairs : csv}
              headers={headers}
              filename={"DVLA Submitted data .csv"}
            >
              <Tooltip title="Download CSV">
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={downloadIcon}
                />
              </Tooltip>
            </CsvIcon>
          </div>
          <Cover>
            <Select
              style={{
                width: 120,
              }}
              placeholder="Site Code "
              onChange={(e) => handleSearch("siteCode", e)}
              options={siteCodes}
              isClearable
              styles={{
                minWidth: 120,
                clearIndicator: () => ({
                  color: "red",
                  marginTop: "3px",
                }),
              }}
            />
          </Cover>
          <Cover>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start date"
              value={startDate}
              // onChange={onChange}
              onChange={(e) => handleStartDate("startDate", e)}
            />
          </Cover>
          <Cover>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End date"
              value={endDate}
              // onChange={onChange}
              onChange={(e) => handleendDate("endDate", e)}
            />
          </Cover>
          <Cover>
            <Select
              placeholder="Source"
              options={sourceData}
              defaultValue={{ value: "ANPR", label: "ANPR" }}
              onChange={(e) => handleSourceChange(e)}
              styles={customStyles}
              menuPortalTarget={document.body}
            />
          </Cover>
          <Cover>
            <Select
              placeholder="Status"
              options={statusData}
              defaultValue={{
                value: "Submitted to DVLA",
                label: "Submitted to DVLA",
              }}
              onChange={(e) => handleStatusChange(e)}
              styles={statusStyles}
              menuPortalTarget={document.body}
              // autosize={false}
            />
          </Cover>
          <Search>
            <Icon>
              <img src={search_icon} alt="Plate number" />
            </Icon>
            <SearchPlateNumber
              type="text"
              placeholder="Plate Number"
              value={search.plate}
              onChange={(e) =>
                handleSearch(
                  "plate",
                  convertToUpperCaseAndRemoveSpaces(e.target.value)
                )
              }
            />
          </Search>
        </RightSection>
      </TopSection>
      <CarsList>
        {isLoading ? (
          <Loader>
            <DataFetchIngLoader />
          </Loader>
        ) : approvedPairs.length > 0 ? (
          approvedPairs.map((data) => (
            <CarDetailsCard key={data.id} data={data} />
          ))
        ) : (
          <NodataCover>
            <NoData />
          </NodataCover>
        )}
      </CarsList>
      <BottomSection>
        <div>
          <span>Rows per page: </span>
          <ANTDSelect
            defaultValue={pageSize}
            onChange={handlePageSizeChange}
            style={{ width: 80 }}
          >
            <ANTDSelect.Option value={10}>10</ANTDSelect.Option>
            <ANTDSelect.Option value={20}>20</ANTDSelect.Option>
            <ANTDSelect.Option value={50}>50</ANTDSelect.Option>
            {/* Add more options as needed */}
          </ANTDSelect>
        </div>

        <RecordText>
          {noRecords
            ? "No Records"
            : `${startNum} - ${endNum} of ${totalRecords} records`}
        </RecordText>

        <Pagination
          total={paginationData?.count}
          currentPage={currentPage}
          pageSize={pageSize}
          onChange={handlePageChange}
          current={currentPage}
        />
      </BottomSection>
    </Container>
  );
}

export default DVLAPairs;
const Container = styled.div`
  padding: 20px;
  .ant-pagination-options {
    display: none;
  }
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  .css-13cymwt-control {
    min-height: 30px !important;
  }
  .css-1xc3v61-indicatorContainer {
    padding: 0 8px !important;
  }
  :where(.css-dev-only-do-not-override-3mqfnx).ant-picker {
    height: 33px;
  }
  .css-t3ipsp-control:hover {
    border-color: #2684ff;
    border: 1px;
    min-height: 30px !important;
  }
`;
const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.h2`
  color: ${COLORS.THEME_COLOR};
  font-size: 16px;
  font-weight: 500;
`;
const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
const Cover = styled.div`
  border-radius: 5px;
  .ant-picker {
  }
  /* border: 1px solid #d0d1d2; */
`;
const Search = styled.div`
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  width: 180px;
  padding: 0 10px;
  max-height: 33px;
  input {
    width: 100%;
    padding: 3px 10px;
  }
`;
const Icon = styled.span`
  display: block;
  width: 20px;
  img {
    display: block;
    width: 100%;
  }
`;
const CarsList = styled.div`
  margin-top: 10px;
  max-height: calc(100vh - 220px);
  min-height: calc(100vh - 220px);
  overflow: scroll;
`;

const BottomSection = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RecordText = styled.div`
  padding: 4px 12px;
  margin-right: 95px;
  font-size: 14px;
  font-weight: 400;
  color: #060606;
  border-radius: 34px;
  border: 0.8px solid #d0d1d2;
`;

const Button = styled.span`
  height: 30px;
  border: 1px solid #3d619b;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d619b;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: #3d619b;
    background-color: #fff;
    /* border: 1px solid #fff; */
  }
`;
const Filter = styled.div`
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  padding: 0 10px;
  img {
    width: 100%;
  }
  p {
    font-size: 12px;
    margin-right: 10px;
    color: #6a6a6a;
  }
  span {
    width: 14px;
  }
`;
const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 230px);
`;
const NodataCover = styled.div`
  min-height: calc(100vh - 230px);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Download = styled(CSVLink)`
  width: 150px;
  height: 35px;
  background-color: ${COLORS.THEME_COLOR};
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
`;
const SearchPlateNumber = styled.input`
  font-family: "Roboto Mono";
  &::placeholder {
    font-family: "Inter", sans-serif;
  }
`;
const CsvIcon = styled(CSVLink)`
  padding: 4px;
`;

const customStyles = {
  minWidth: 120,
  fontSize: "14px !important",
  menuPortal: (base) => ({
    ...base,
    zIndex: "99999 !important",
  }),

  clearIndicator: () => ({
    color: "red",
    marginTop: "3px",
  }),

  control: (provided, state) => ({
    ...provided,
    maxWidth: "140px",
    minHeight: "10px",
  }),

  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "14px",
  }),

  menu: (provided, state) => ({
    ...provided,
    width: "105px",
  }),
};

const statusStyles = {
  fontSize: "14px !important",
  menuPortal: (base) => ({
    ...base,
    zIndex: "99999 !important",
  }),

  clearIndicator: () => ({
    color: "red",
    marginTop: "3px",
  }),

  control: (provided, state) => ({
    ...provided,
    width: "225px",
    minHeight: "10px",
  }),

  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "14px",
  }),

  menu: (provided, state) => ({
    ...provided,
    width: "200px",
  }),
};
